import React from "react";
import Layout1 from '../../Layouts/Layout1'
import Editor from "./Editor";

const AddBlog = () => {
  return (
    <Layout1>
      <Editor />
    </Layout1>
  )
}

export default AddBlog